// IMAGE SPOT
import WDHImageSpotAccordion from "../../../../wdh-feature/wdh-image-spot/src/js/variations/accordion";
import WDHImageSpot from "../../../../wdh-feature/wdh-image-spot/src/js/wdh-image-spot";
var imageSpot = new WDHImageSpot();
imageSpot.useEqualHeaderHeight = true;
imageSpot.useEqualBodyHeight = true;
imageSpot.registerVariation(new WDHImageSpotAccordion());
imageSpot.run();
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    window.DGS.Resizer.getInstance().register(function () {
      $('.image-spot .image-spot-element').each(function () {
        var $this = $(this),
          $link = $('.image-spot-element-link', $this),
          elemWidth = $link.outerWidth(),
          $a = $('a', $link),
          linkWidth = $a.length ? $a.outerWidth() : 0;
        $link.css('padding-left', elemWidth / 2 - linkWidth / 2 + "px");
      });

      // Show proper image-spot context on resize
      $('.image-spot').each(function () {
        var $this = $(this),
          $activeLi = $('li.active', $this),
          $prevLi,
          isStacked = false,
          $content = $('.image-spot-element-context', $activeLi),
          $componentContent = $activeLi.closest('.component-content'),
          $container = $('.context-container', $componentContent);

        // check if elements are stacked.  Then based on that we will hide/show the proper context container
        $activeLi.siblings('.image-spot-element').each(function () {
          var $this = $(this);
          if ($prevLi != null) {
            if ($this.offset().top > $prevLi.offset().top) {
              isStacked = true;
            }
          }
          $prevLi = $this;
        });
        if ($content.length && isStacked) {
          $content.css('display', 'block');
          $container.css('display', 'none');
        } else {
          $content.css('display', 'none');
          $container.css('display', 'block');
        }
      });
    });
    function onAddContentClick(event, data) {
      var $this = $(this),
        $ul = $this.closest('ul'),
        $lis = $('.image-spot-element', $ul),
        $li = $this.closest('.image-spot-element'),
        liIndex = $li.index(),
        index = $li.data('context-content-index'),
        division = $li.data('context-content-division'),
        $contentlist = $('.image-spot-element-context', $ul),
        $content = $('.image-spot-element-context', $li),
        divisionWidth = 100 / division,
        selectorleft = divisionWidth * liIndex + divisionWidth / 2,
        $componentContent = $this.closest('.component-content'),
        $container = $('.context-container', $componentContent),
        isStacked = $lis.length === 1,
        $prevLi;
      event.preventDefault();
      if ($content) {
        $lis.each(function () {
          var $this = $(this);
          if ($prevLi != null) {
            if ($this.offset().top > $prevLi.offset().top) {
              isStacked = true;
            }
          }
          $prevLi = $this;
        });
        if (!$container.length) {
          $container = $('<div class="context-container"></div>');
          $componentContent.append($container);
        }
        $lis.removeClass('active');
        if (!isStacked) {
          if ($container.data('context-content-index') !== index) {
            $container.slideUp(0, function () {
              $li.addClass('active');
              $container.html($content.children().clone(true));
              // get selectors border width
              var sbw = parseFloat($('.image-spot-element-context-selector', $container).css('border-left-width')),
                // get active li's padding-left and padding-right
                apl = parseFloat($li.css('padding-left')) / 2,
                apr = parseFloat($li.css('padding-right')) / 2,
                // get context-container width
                ccw = $container.outerWidth(),
                // calculate percent
                percent = (selectorleft / 100 * ccw - sbw + apl - apr) / ccw;
              $('.image-spot-element-context-selector', $container).css('left', percent * 100 + '%');
              if (data != null && !data.animate) {
                $container.show();
              } else {
                $container.slideDown(0);
              }
            });
            $container.data('context-content-index', index);
          } else {
            $container.slideUp(0);
            $container.data('context-content-index', "-1");
          }
        } else {
          if ($container.data('context-content-index') !== index) {
            $container.data('context-content-index', index);
            $li.addClass('active');
          } else {
            $container.data('context-content-index', "-1");
          }
          //copy to other content container in case of browser resize
          $container.html($content.children().clone(true));
          $container.css('display', 'none');
          $contentlist.each(function () {
            if (this !== $content[0]) {
              $(this).hide('fast');
            } else {
              $content.slideToggle("slow");
            }
          });
          $('.image-spot-element-context-selector', $container).css('left', selectorleft + '%');
        }
      }
    }
    $('.image-spot:not(.accordion)').each(function () {
      var $imageSpot = $(this),
        index = 0,
        numLi = $('.image-spot-element', $imageSpot).length;
      $('.image-spot-element', $imageSpot).each(function () {
        var $this = $(this),
          $link = $('.image-spot-element-link a', $this),
          href = $link.attr('href'),
          target = $link.attr('target'),
          title = $link.attr('title') !== undefined ? $link.attr('title') : '',
          $context = $('.image-spot-element-context', $this),
          $image = $('.image-spot-element-image', $(this));
        if ($context.length) {
          href = '#';
          $link.attr('href', href);
          $this.data('context-content-index', index);
          $this.data('context-content-division', numLi);
          $image.wrap('<a href="' + href + '"></a>');
          $image.parent().on('click', onAddContentClick);
          $link.on('click', onAddContentClick);
        } else if (href !== undefined && href !== "") {
          $image.wrap('<a href="' + href + '" target="' + target + '" title="' + title.replace(/\</g, '[').replace(/\>/g, ']').replace(/"/g, "'") + '"> </a>');
        }
        index++;
      });
    });
    $('.component.image-spot.accordion .image-spot-element').each(function () {
      var $this = $(this),
        $header = $('.image-spot-element-header', $this),
        $image = $('.image-spot-element-image', $this),
        $text = $('.image-spot-element-text', $this),
        $contentWrapper = $('<div class="image-spot-element-content"></div>');
      $contentWrapper.insertAfter($header);
      $contentWrapper.append($('<div class="spacer"></div>'));
      $contentWrapper.append($image);
      $contentWrapper.append($text);
      $contentWrapper.append($('<div class="mask"></div>'));
    });

    /* if video added inside image spot and text image spot */

    var videoDiv = $('.component.video-in-image-spot');
    var imageSpotEle = $('.video-in-image-spot .image-spot-element');
    var textSpotEle = $('.video-in-image-spot .text-image-spot-image');
    if (videoDiv) {
      var playBtn = '<div class="video-spot-play-btn"></div>';
      imageSpotEle.each(function (index, element) {
        var hdTitle = $(element).find('.image-spot-element-header').html();
        $(element).find('a:first .image-spot-element-image').append(playBtn);
        $(element).prepend(hdTitle);
        $(element).find('.image-spot-element-header').remove();
      });
      textSpotEle.each(function (index, element) {
        $(element).find('a').append(playBtn);
      });
    }

    // End
  });
})(jQuery);